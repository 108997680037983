import countriesImport from "./countries.i18n-iso";

export default class Countries {

    static locale: string = "en"

    static setLocale(locale: string) {
        this.locale = locale;
    }

    static getCountryName(isoCountryCode: string): string {
        const countries: any = countriesImport;
        // eslint-disable-next-line @typescript-eslint/typedef

        if (countries === undefined) {
            console.warn("[countries] Not loaded yet!");
            return "";
        }

        let name = countries.getName(isoCountryCode, this.locale);

        console.log("countryCode: ", isoCountryCode, "  -- name: ", name);

        if (name === undefined) return isoCountryCode; // use code as fallback

        // fix for wrong name from i18-iso-countries
        if (name === "Viet Nam") name = "Vietnam";
        if (name === "Islamic Republic of Iran") name = "Iran";

        if ((name.endsWith("Republic of") || name.endsWith("Republic of the")) && name.includes(","))
        {
            const names = name.split(",");
            name = names[1] + " " + names[0];
        }
        return name.trim();
    }

}
